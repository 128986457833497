import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { SelectOption, UIButton, UISelect, UITypography } from 'shared/ui';
import { ReactComponent as TranslateIcon } from 'assets/icons/translate.svg';
import { ReactComponent as AttachCircleIcon } from 'assets/icons/attach-circle.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/question-mark.svg';
import { ReactComponent as BookIcon } from 'assets/icons/book-icon.svg';

import {
  CS_KEYS,
  LANG_SELECT_OPTIONS,
  LEFT_SWIPE_CHILD_VARIANTS,
  LEFT_SWIPE_PARENT_VARIANTS,
  ROUTES,
  SUPPORTED_LANGUAGES,
} from 'shared/constants';
import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { fetchFaq, getAchievements, getLeagueInfo, getTasks, updateUser } from 'shared/api';
import { useGlobalStore, useProgressStore, useReferralsStore } from 'shared/store';
import { Wallet } from 'components';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { Swipe } from 'shared/ui/animations';
import { m } from 'framer-motion';
import { AxiosResponse } from 'axios';
import { Faq, TaskEntity, AchievementEntity } from 'shared/types';
import { handleSettledPromise, replaceUrl } from 'shared/utils';

export const SettingsModule = () => {
  const { t, i18n } = useTranslation();
  const { setItem } = useCloudStorage();
  const user = useGlobalStore((state) => state.user);
  const updateStoreUser = useGlobalStore((state) => state.updateUser);
  const setFaq = useGlobalStore((state) => state.setFaq);
  const [selectedLang, setSelectedLang] = useState(user.language || LANG_SELECT_OPTIONS[0].value);
  const [loading, setLoading] = useState(false);
  const setStaticAchievements = useProgressStore((state) => state.setStaticAchievements);
  const setTasks = useProgressStore((state) => state.setTasks);
  const setDynamicAchievements = useProgressStore((state) => state.setDynamicAchievements);
  const setLeagueInfo = useReferralsStore((state) => state.setLeagueInfo);
  const navigate = useNavigate();

  const defaultLangOption =
    LANG_SELECT_OPTIONS.find((option) => option.value === selectedLang) || LANG_SELECT_OPTIONS[0];

  const handleLangChange = async (selectedOption: SelectOption) => {
    setSelectedLang(selectedOption.value as SUPPORTED_LANGUAGES);
  };

  const saveSettings = async () => {
    try {
      setLoading(true);
      if (i18n.language !== selectedLang) {
        await updateUser({ language: selectedLang });
        await setItem(CS_KEYS.LANGUAGE, selectedLang);
        i18n.changeLanguage(selectedLang);
        updateStoreUser({ language: selectedLang });

        const [tasksRes, staticAchievementsRes, dynamicAchievementsRes, faqRes] =
          await Promise.allSettled([
            getTasks(),
            getAchievements('static'),
            getAchievements('dynamic'),
            // getLeagueInfo(),
            fetchFaq(),
          ]);
        // handleSettledPromise<AxiosResponse<LeagueInfo>>({
        //   promise: leagueInfoRes,
        //   callback: (result) => {
        //     setLeagueInfo(result.data);
        //   },
        // });
        handleSettledPromise<AxiosResponse<Faq[]>>({
          promise: faqRes,
          callback: (result) => {
            setFaq(result.data);
          },
        });
        handleSettledPromise<AxiosResponse<TaskEntity[]>>({
          promise: tasksRes,
          callback: (result) => {
            setTasks(result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })));
          },
        });
        handleSettledPromise<AxiosResponse<AchievementEntity[]>>({
          promise: staticAchievementsRes,
          callback: (result) => {
            setStaticAchievements(
              result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })),
            );
          },
        });
        handleSettledPromise<AxiosResponse<AchievementEntity[]>>({
          promise: dynamicAchievementsRes,
          callback: (result) => {
            setDynamicAchievements(
              result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })),
            );
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      navigate(ROUTES.MAIN);
    }
  };

  return (
    <div className={styles.root}>
      <m.div
        className={styles.content}
        variants={LEFT_SWIPE_PARENT_VARIANTS}
        initial="hidden"
        animate="visible"
      >
        <UITypography variant="H1" className={styles.header} component="h1">
          {t('settings.settings')}
        </UITypography>

        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <div className={styles.settingSection}>
            <UITypography iconLeft={<WalletIcon />} className={styles.sectionHeader}>
              {t('settings.wallet')}
            </UITypography>
            <Wallet />
          </div>
        </Swipe>

        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <div className={styles.settingSection}>
            <UITypography iconLeft={<TranslateIcon />} className={styles.sectionHeader}>
              {t('settings.change_language')}
            </UITypography>
            <UISelect
              options={LANG_SELECT_OPTIONS}
              onChange={handleLangChange}
              placeholder={t('choose_language_screen.select_language')}
              defaultValue={defaultLangOption}
            />
          </div>
        </Swipe>

        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <div className={styles.settingSection}>
            <UITypography iconLeft={<AttachCircleIcon />} className={styles.sectionHeader}>
              {t('settings.donut_settings')}
            </UITypography>
            <Link
              to={`${ROUTES.CHOOSE_FINGER}?from=settings`}
              className={styles.fingerSettingsLink}
            >
              <UITypography>{t('settings.go_to_donut_setting')}</UITypography>
              <ArrowDownIcon className={styles.arrowRight} />
            </Link>
          </div>
        </Swipe>

        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <div className={styles.settingSection}>
            <UITypography iconLeft={<QuestionMarkIcon />} className={styles.sectionHeader}>
              {'FAQ'}
            </UITypography>
            <Link to={ROUTES.FAQ} className={styles.fingerSettingsLink}>
              <UITypography>{t('common.visit')}</UITypography>
              <ArrowDownIcon className={styles.arrowRight} />
            </Link>
          </div>
        </Swipe>

        <Swipe variants={LEFT_SWIPE_CHILD_VARIANTS}>
          <div className={styles.settingSection}>
            <UITypography iconLeft={<BookIcon />} className={styles.sectionHeader}>
              {t('settings.onboarding')}
            </UITypography>

            <Link
              to={ROUTES.ONBOARDING}
              state={{ prevPath: ROUTES.SETTINGS }}
              className={styles.fingerSettingsLink}
            >
              <UITypography>{t('common.visit')}</UITypography>
              <ArrowDownIcon className={styles.arrowRight} />
            </Link>
          </div>
        </Swipe>
      </m.div>

      <Swipe direction="horizontal" from="right" style={{ width: '100%' }}>
        <UIButton loading={loading} onClick={saveSettings} fullwidth>
          {t('common.save')}
        </UIButton>
      </Swipe>
    </div>
  );
};
