import styles from './style.module.scss';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DEFAULT_FINGER_SLIDER_CONFIG } from 'shared/constants';
import { DonutSize } from 'shared/types';
import { StarsMain } from 'components/lottie';
import { audioManager } from 'shared/service/audio';

export const UserSizeDonut = ({ onGameStart }: { onGameStart: () => void }) => {
  const user = useGlobalStore((state) => state.user);
  const gameReady = useGameStore((state) => state.gameReady);
  const resetGameState = useGameStore((state) => state.resetGameState);

  const fingerSize = (parseInt(user.finger_size || '') ||
    DEFAULT_FINGER_SLIDER_CONFIG.min) as DonutSize;

  const onClick = async () => {
    if (gameReady) {
      await audioManager.initialize(); // same in session-cooldown-timer
      resetGameState();
      // navigate(ROUTES.SESSION);
      onGameStart();
    }
  };

  return (
    <div className={styles.userSizeDonutRoot}>
      <div className={styles['blue-donut-container']}>
        <div className={`${styles['blue-donut-outer-ring']} ${gameReady ? styles.pulse : ''}`}>
          <div className={styles['blue-donut-inner-ring']} onClick={onClick}>
            <img
              src="/new-year/donut_home.webp"
              alt="blue-donut"
              className={styles['blue-donut']}
              width={120}
              height={120}
            />
            <div
              className={styles.donutCenter}
              style={{ width: '47px', height: '44px', transform: 'translateY(-1px)' }}
            />
          </div>
        </div>
      </div>
      <div className={styles.starsContainer}>
        <StarsMain />
      </div>
    </div>
  );
};
