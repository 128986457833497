import styles from './style.module.scss';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UIButton, UITypography, FingerSelect } from 'shared/ui';
import {
  DEFAULT_FINGER_SLIDER_CONFIG,
  SUPPORTED_LANGUAGES,
  ROUTES,
  CS_KEYS,
  GAME_COIN_SHORTCUT,
} from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import { createUser, updateUser } from 'shared/api';
import { FingerSizes } from 'shared/types';
import { useCloudStorage, useInitData } from '@vkruglikov/react-telegram-web-app';
import { appSessionStorage } from 'shared/service';
import { Scale, Swipe } from 'shared/ui/animations';
import { useGameStore, useGlobalStore } from 'shared/store';

export const ChooseFingerModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [initDataUnsafe, initData] = useInitData();
  const user = useGlobalStore((state) => state.user);
  const updateUserStore = useGlobalStore((state) => state.updateUser);
  const toggleRefetchAppData = useGlobalStore((state) => state.toggleRefetchAppData);
  const setGlobalLoading = useGlobalStore((state) => state.setGlobalLoading);
  const [fingerSize, setFingerSize] = useState(
    parseInt(user.finger_size || `${DEFAULT_FINGER_SLIDER_CONFIG.min}`),
  );
  const [loading, setLoading] = useState(false);
  const { getItem } = useCloudStorage();
  const setGameReady = useGameStore((state) => state.setGameReady);

  const tgUser = initDataUnsafe?.user;

  const onFingerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFingerSize(+e.target.value);
  };

  const tryToUpdateUser = async () => {
    const token = appSessionStorage.getToken();

    if (!token && initData) {
      appSessionStorage.setToken(initData);
    }

    await updateUser({ finger_size: `${fingerSize}px` as FingerSizes });
    updateUserStore({ finger_size: `${fingerSize}px` as FingerSizes });
  };

  const onGoPlay = async () => {
    setLoading(true);

    try {
      const userLanguage = await getItem(CS_KEYS.LANGUAGE);
      // console.log('Current user ID: ', user.tg_user_id);

      if (user.tg_user_id) {
        await tryToUpdateUser();
      } else {
        await createUser({
          tg_user_id: tgUser?.id || 0,
          username: tgUser?.username || tgUser?.id?.toString() || '',
          first_name: tgUser?.first_name || '',
          last_name: tgUser?.last_name || '',
          finger_size: `${fingerSize}px` as FingerSizes,
          language: (userLanguage as SUPPORTED_LANGUAGES) || SUPPORTED_LANGUAGES.RU,
        });
      }

      setGameReady(true);
    } catch (error) {
      console.error('Error doing something with your finger: ', error);
      await tryToUpdateUser();
    } finally {
      setGlobalLoading(true);
      toggleRefetchAppData();
      navigate(ROUTES.MAIN);
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Scale style={{ height: '100%' }}>
        <div className={styles['finger-select-container']}>
          <UITypography variant="H1" component="h1" preline className={styles.title}>
            {`${t('choose_donut_screen.title')} ${GAME_COIN_SHORTCUT}`}
          </UITypography>
          <UITypography component="p" className={styles.subtitle}>
            {t('choose_donut_screen.subtitle')}
          </UITypography>

          <FingerSelect
            onChange={onFingerChange}
            defaultValue={parseInt(user.finger_size || `${DEFAULT_FINGER_SLIDER_CONFIG.min}`)}
          />
        </div>
      </Scale>

      <Swipe direction="horizontal" from="right" style={{ width: '100%' }}>
        <UIButton disabled={loading} fullwidth onClick={onGoPlay}>
          {t('common.save')}
        </UIButton>
      </Swipe>
    </div>
  );
};
