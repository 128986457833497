import { createWithEqualityFn } from 'zustand/traditional';
import { GetSettingsResponse } from 'shared/types';
import { deepShallow } from 'shared/utils';

type SessionConfig = {
  total_donuts_count: number;
  useful_donuts_points: number[];

  donut_spacing: number;
  session_duration: number;

  first_phase_ends_at_seconds: number;
  second_phase_ends_at_seconds: number;
  v0: number;
  v1: number;
  v2: number;
  v3: number;

  user_id: string;
  uuid: string;
  last_start: string;
};

type GameSettings = Omit<GetSettingsResponse, 'daily_donut_limit'>;

type GameState = {
  currCoins: number;
  gameFinished: boolean;
  frozen: boolean;
  gameReady: boolean;
  settings: GameSettings;
  sessionId: string;
  catchedBees: number;
  failedDonuts: number;
  donutsCleared: boolean;

  sessionConfig: SessionConfig | null;
  sessionStartTime: number | null;
  elapsedTime: number;
  containerHeight: number;
  currentPhase: 1 | 2 | 3;
  donutSequence: number[];
  currentDonutIndex: number;
  actualSessionDuration: number | null;
  booseters: {
    isTurboBoostActive: boolean;
  };
};

type GameActions = {
  addCurrCoins: (count: number) => void;
  setGameFinished: (gameFinished: boolean) => void;
  setCurrCoins: (currCoins: number) => void;
  setFrozen: (frosen: boolean) => void;
  setGameReady: (gameReady: boolean) => void;
  setSettings: (newSettings: Partial<GameSettings>) => void;
  setSessionId: (sessionId: string) => void;
  increaseCatchedBees: (value: number) => void;
  increaseFailedDonuts: (value: number) => void;
  setDonutsCleared: (value: boolean) => void;
  resetGameState: () => void;

  setSessionConfig: (config: SessionConfig) => void;
  setContainerHeight: (height: number) => void;
  updateGameTime: () => void;
  initializeDonutSequence: () => void;
  setCurrentDonutIndex: (index: number) => void;
  updateBooster: <K extends keyof GameState['booseters']>(
    key: K,
    value: GameState['booseters'][K],
  ) => void;
};

type GameSessionStore = GameState & GameActions;

const initialState: GameState = {
  currCoins: 0,
  gameFinished: false,
  frozen: false,
  gameReady: false,
  catchedBees: 0,
  failedDonuts: 0,
  sessionId: '',
  donutsCleared: false,
  settings: {
    conversion_rate: 0,
    cooldown_duration: 2, //seconds
    created_at: '',
    fall_bee_interval: 0.9, //seconds
    fall_bee_speed: 3, //seconds
    session_duration: 60, //seconds
    updated_at: '',
    uuid: '',
    waiting_time: 180, //seconds
    daily_bonus_limit: 1000,
    min_conversion_amount: 0.0003,
    max_count_ads: 0,
    skip_waiting_once_price: 0,
    useful_donuts_ratio: 0,
    bronze_league_percent_award: 0,
    silver_league_percent_award: 0,
    gold_league_percent_award: 0,
    diamond_league_percent_award: 0,
    v0: 0,
    v1: 0,
    v2: 0,
    v3: 0,
    donut_spacing: 0,
    first_phase_ends_at: 0,
    second_phase_ends_at: 0,
    bakery_unlock_price: 0,
    max_points_per_session: 0,
    points_variance_per_session: 0,
  },
  sessionConfig: null,
  sessionStartTime: null,
  elapsedTime: 0,
  containerHeight: 0,
  currentPhase: 1,
  donutSequence: [],
  currentDonutIndex: 0,
  actualSessionDuration: null,
  booseters: {
    isTurboBoostActive: false,
  },
};

// Функция для правильного перемешивания (алгоритм Фишера-Йейтса)
function shuffle<T>(array: T[]): T[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

export const useGameStore = createWithEqualityFn<GameSessionStore>(
  (set, get) => ({
    ...initialState,
    addCurrCoins: (count) =>
      set(({ currCoins }) => ({
        currCoins: currCoins + count,
      })),
    setGameFinished: (gameFinished) => set(() => ({ gameFinished })),
    setCurrCoins: (currCoins) => set(() => ({ currCoins })),
    setFrozen: (frozen) => set(() => ({ frozen })),
    setGameReady: (gameReady) => set(() => ({ gameReady })),
    setSettings: (newSettings: Partial<GameSettings>) =>
      set((state) => ({ settings: { ...state.settings, ...newSettings } })),
    setSessionId: (sessionId: string) => set(() => ({ sessionId })),
    increaseCatchedBees: (value: number) =>
      set((state) => ({ catchedBees: state.catchedBees + value })),
    increaseFailedDonuts: (value: number) =>
      set((state) => ({ failedDonuts: state.failedDonuts + value })),
    setDonutsCleared: (donutsCleared) => set(() => ({ donutsCleared })),
    resetGameState: () =>
      set((state) => {
        const {
          catchedBees,
          sessionId,
          gameFinished,
          frozen,
          donutsCleared,
          failedDonuts,
          gameReady,
          currCoins,
          sessionConfig,
          sessionStartTime,
          elapsedTime,
          currentPhase,
          donutSequence,
          currentDonutIndex,
          actualSessionDuration,
        } = initialState;

        return {
          catchedBees,
          sessionId,
          gameFinished,
          frozen,
          donutsCleared,
          failedDonuts,
          gameReady,
          currCoins,
          sessionConfig,
          sessionStartTime,
          elapsedTime,
          currentPhase,
          donutSequence,
          currentDonutIndex,
          actualSessionDuration,
        };
      }),

    setSessionConfig: (config: SessionConfig) => {
      // add enough time to catch the last donut
      const actualDuration = config.session_duration + 1 / config.v3 + 0.2;
      set(() => ({
        sessionConfig: config,
        actualSessionDuration: actualDuration,
        sessionStartTime: Date.now(),
        currentPhase: 1,
        elapsedTime: 0,
      }));
    },

    setContainerHeight: (height: number) =>
      set(() => ({
        containerHeight: height,
      })),

    updateGameTime: () => {
      const state = get();
      if (!state.sessionStartTime || !state.sessionConfig || !state.actualSessionDuration) return;

      const elapsed = (Date.now() - state.sessionStartTime) / 1000;
      const newGameFinished = elapsed >= state.actualSessionDuration;

      // Only update gameFinished if it actually changed
      if (newGameFinished !== state.gameFinished) {
        set({ gameFinished: newGameFinished });
      }

      // Separate phase update to minimize re-renders
      const phase =
        elapsed <= state.sessionConfig.first_phase_ends_at_seconds
          ? 1
          : elapsed <= state.sessionConfig.second_phase_ends_at_seconds
            ? 2
            : 3;

      if (phase !== state.currentPhase) {
        set({ currentPhase: phase });
      }

      // Always update elapsedTime but in its own set call
      set({ elapsedTime: elapsed });
    },

    initializeDonutSequence: () => {
      const state = get();
      if (!state.sessionConfig) return;

      const usefulDonuts = state.sessionConfig.useful_donuts_points;
      const emptyDonuts = Array(state.sessionConfig.total_donuts_count - usefulDonuts.length).fill(
        0,
      );

      // Перемешиваем массив
      const sequence = shuffle([...usefulDonuts, ...emptyDonuts]);

      if (sequence.length >= 3) {
        const targetIndex = sequence.length - 3;
        if (sequence[targetIndex] === 0) {
          // Ищем первый ненулевой элемент с конца
          for (let i = sequence.length - 1; i >= 0; i--) {
            if (sequence[i] !== 0) {
              [sequence[targetIndex], sequence[i]] = [sequence[i], sequence[targetIndex]];
              break;
            }
          }
        }
      }

      set({
        donutSequence: sequence,
        currentDonutIndex: 0,
      });
    },

    setCurrentDonutIndex: (index: number) => set({ currentDonutIndex: index }),
    updateBooster(key, value) {
      set((state) => ({
        ...state,
        booseters: {
          ...state.booseters,
          [key]: value,
        },
      }));
    },
  }),
  deepShallow,
);
