import { SUPPORTED_LANGUAGES } from 'shared/constants/common';
import { Nullable } from 'shared/types/utils';

export type FingerSizes = '39px' | '44px' | '49px' | '54px' | '59px';

export type AppUser = {
  created_at: string;
  updated_at: string;
  uuid: string;
  tg_user_id: number;
  username: string;
  first_name: string;
  last_name: string;
  /** Кол-во токенов BoBe  */
  tokens: number | string;
  language: SUPPORTED_LANGUAGES | null;
  finger_size: FingerSizes | null;
  last_start: string;
  daily_limit: number;
  max_daily_limit: number;
  wallet: string;
  league: LeagueType;
  /** Кол-во монет DON */
  coins: number | string;
  has_bakery_access: boolean;
  current_limit: number;
  daily_bonus_limit: number;
  current_count_ads: number;
  is_blocked: boolean;
  last_finish: Nullable<string>;
  next_available_start: Nullable<string>;
};

export type DonutSize = 29 | 34 | 39 | 44 | 49 | 54 | 59;

export type DonutVariant = 'blue' | 'chocolate' | 'orange' | 'purple' | 'yellow';

export type DonutEntity = {
  type: DonutVariant;
  size: DonutSize;
  id: string;
  positionX: number;
  value: number;
  fallDuration: number;
  startDelay?: number;
};

export type FlyEntity = {
  id: string;
  startX: number;
  startY: number;
  endX: number;
  endY: number;
  rotation: number;
  startDelay: number;
};

export type LeagueType = 'bronze' | 'silver' | 'gold' | 'diamond';

export type LeagueVariantEntity = {
  type: LeagueType;
  name: string;
  id: number;
  icon: string;
};

export type ReferralEntity = {
  uuid: string;
  username: string;
  first_name: string;
  last_name: string;
  tg_user_id: number;
  percentage_award: number;
  is_approved: boolean;
  level: number;
};

export type TimerReturnValue = {
  gameTime: { hours: string; minutes: string; secondsLeft: string };
  seconds: number;
};

export type TaskStatus = 'to_do' | 'pending' | 'done';
export type TaskCondition = 'subscribe' | 'visit_site';

export type TaskEntity = {
  id: string;
  title: string;
  icon: string;
  status: TaskStatus;
  level: number | null;
  condition: TaskCondition;
  assignment: string;
  award_kind: TaskAwardType;
  award_value: number;
  due_date: string;
  description: string;
};

export type AchievementType = 'static' | 'dynamic';
export type AchievementKind = 'score' | 'combo' | 'static';
export type AchievementGoal =
  | 'bee'
  | 'max_catch_bagels'
  | 'catch_bagels'
  | 'completed_tasks'
  | 'invited_friends'
  | 'failed_bagels';
export type AchievementCondition = 'session' | 'per_day' | 'per_month' | 'select_date';

export type AchievementEntity = {
  title: string;
  icon: string;
  description: string;
  type: AchievementType;
  kind: AchievementKind;
  goal: AchievementGoal;
  condition: AchievementCondition;
  value: number;
  from_date: string;
  to_date: string;
  is_active: boolean;
  current_value: number;
};

export type Faq = {
  created_at: string;
  updated_at: string;
  uuid: string;
  question: string;
  answer: string;
};

export type NextLegueRequirements = {
  title: string;
  completed: boolean;
  id: string;
};

export type LeagueInfo = {
  title: string;
  price: number;
  requirements: NextLegueRequirements[];
  kind: LeagueType;
  daily_limit: number;
};

export type TaskAwardType = 'tokens' | 'coins';

export type BotInfo = {
  lang: SUPPORTED_LANGUAGES;
};

export enum AdsGramErrorType {
  NO_ADS = 'no_ads',
  // OFTEN = 'often',
  UNKNOWN = 'unknown',
  NO_SCRIPT = 'no_script',
}
