import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { useGlobalStore } from 'shared/store';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectWalletModal } from 'components';
import { TickCircle } from 'assets/icons/tick-circle';
import walletImage from 'assets/images/wallet-front-color.png';
import { buildUrl } from 'shared/utils';
import { getToken } from 'shared/api';
import { sentryClient } from 'index';

export const Wallet = () => {
  const { t } = useTranslation();
  const user = useGlobalStore((state) => state.user);
  const WebApp = useWebApp();

  const [visibleConnectWalletModal, setVisibleConnectWalletModal] = useState(false);

  const openConnectLink = async () => {
    try {
      const res = await getToken();
      const connectLink = buildUrl(process.env.REACT_APP_BAKERY_URL || '', {
        token: res.access_token,
      });
      setVisibleConnectWalletModal(true);
      WebApp.openLink(connectLink);
    } catch (error) {
      sentryClient?.captureException(error, {
        captureContext(scope) {
          scope.setTag('location', 'useGame');
          return scope;
        },
      });
      console.log(error);
      alert(t('alerts.something_went_wrong'));
    }
  };

  return (
    <div>
      {user.wallet ? (
        <div className={styles.walletConnectedRoot}>
          <div className={styles.walletConnectedContent}>
            <img src={walletImage} alt="" width={40} height={40} style={{ flexShrink: 0 }} />
            <div className={styles.walletInfo}>
              <UITypography component="p" variant="H2" style={{ flexShrink: 0 }}>
                {`${t('settings.wallet')}:`}
              </UITypography>

              <UITypography>
                {user.wallet.slice(0, 4) + '****' + user.wallet.slice(-4)}
              </UITypography>
            </div>
          </div>

          <TickCircle />
        </div>
      ) : (
        <UIButton fullwidth disabled onClick={openConnectLink}>
          {/* {t('settings.connect_wallet_modal.connect_wallet')} */}
          {t('common.coming_soon')}
        </UIButton>
      )}

      <ConnectWalletModal
        visible={visibleConnectWalletModal}
        onClose={() => setVisibleConnectWalletModal(false)}
      />
    </div>
  );
};
