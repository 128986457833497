import styles from './style.module.scss';
import { UIButton, UIModal, UIModalProps, UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { completeTask } from 'shared/api';
import { useEffect, useState } from 'react';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { TaskEntity } from 'shared/types';
import { useGlobalStore, useProgressStore } from 'shared/store';
import { TASK_STATUS, GAME_COIN_SHORTCUT, TOKEN_SHORTCUT, TASK_AWARD_TYPE } from 'shared/constants';
import { replaceUrl } from 'shared/utils';
import { useProgress } from 'shared/api/hooks/use-progress';

type TaskModalProps = Omit<UIModalProps, 'children'> & {
  task: TaskEntity;
};

export const TaskModal = ({ onClose, visible, task }: TaskModalProps) => {
  const { t } = useTranslation();
  const webApp = useWebApp();
  const [buttonText, setButtonText] = useState('complete');
  const [buttonVariant, setbuttonVariant] = useState<'primary' | 'secondary'>('primary');
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [loading, setloading] = useState(false);
  const updateTask = useProgressStore((state) => state.updateTask);
  const addUserCoins = useGlobalStore((state) => state.addUserCoins);
  const addUserTokens = useGlobalStore((state) => state.addUserTokens);
  const setCurrentTask = useProgressStore((state) => state.setCurrentTask);
  const { fetchTasksAndAchievements } = useProgress();

  const { assignment, award_value, award_kind, icon, title, description, id, status } = task;

  const visitLink = (link: string) => {
    if (
      link.startsWith('https://t.me/BoBeBakeryBot') ||
      link.startsWith('https://t.me/BoBeBakeryRobot')
    ) {
      webApp.openLink(link);
      return;
    }

    if (link.startsWith('https://t.me')) {
      webApp.openTelegramLink(link);
      return;
    }

    webApp.openLink(assignment);
  };

  const onComplete = async () => {
    try {
      setloading(true);
      if (assignment && status === TASK_STATUS.TO_DO) {
        visitLink(assignment);
      }

      const res = await completeTask(id);
      await fetchTasksAndAchievements();

      if (res.data.status === TASK_STATUS.PENDING) {
        setButtonText('check');
        setbuttonVariant('secondary');
        updateTask({ ...res.data, icon: replaceUrl(res.data.icon) });
        setCurrentTask({ ...res.data, icon: replaceUrl(res.data.icon) });
        return;
      }
      if (res.data.status === TASK_STATUS.DONE) {
        setButtonText('completed');
        setbuttonVariant('primary');
        setbuttonDisabled(true);

        updateTask({ ...res.data, icon: replaceUrl(res.data.icon) });
        setCurrentTask({ ...res.data, icon: replaceUrl(res.data.icon) });

        if (TASK_AWARD_TYPE[award_kind].toLowerCase().trim() === GAME_COIN_SHORTCUT.toLowerCase()) {
          addUserCoins(award_value);
        } else if (
          TASK_AWARD_TYPE[award_kind].toLowerCase().trim() === TOKEN_SHORTCUT.toLowerCase()
        ) {
          addUserTokens(award_value);
        }
      }
    } catch (error) {
      console.log(error);
      setButtonText('check');
      setbuttonVariant('secondary');
      updateTask({ ...task, status: TASK_STATUS.PENDING, icon: replaceUrl(icon) });
      setCurrentTask({ ...task, status: TASK_STATUS.PENDING, icon: replaceUrl(icon) });
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (status === TASK_STATUS.TO_DO && assignment) {
      setButtonText('complete');
      setbuttonVariant('primary');
      setbuttonDisabled(false);
      return;
    }
    if (status === TASK_STATUS.PENDING) {
      setButtonText('check');
      setbuttonVariant('secondary');
      setbuttonDisabled(false);
      return;
    }
    if (status === TASK_STATUS.DONE) {
      setButtonText('completed');
      setbuttonVariant('primary');
      setbuttonDisabled(true);
    }
  }, [status, visible]);

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.content}>
        <img src={icon} alt="" width={80} height={80} />
        <UITypography variant="H1">{title}</UITypography>
        <UITypography component="p">{description}</UITypography>
        {assignment && (
          <UIButton fullwidth onClick={() => visitLink(assignment)}>
            {t('common.visit')}
          </UIButton>
        )}
        <UIButton
          variant={buttonVariant}
          disabled={buttonDisabled}
          loading={loading}
          onClick={onComplete}
          fullwidth
        >
          {t(`common.${buttonText}`)}
        </UIButton>
      </div>
    </UIModal>
  );
};
