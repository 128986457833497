import React, { FC } from 'react';

import styles from './style.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface Props {
  level: number;
  onChange: (level: number) => void;
}

const TabsReferrals: FC<Props> = ({ level, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tabs}>
      {[0, 1, 2, 3, 4].map((tab, index) => (
        <div
          className={classNames(styles.tab, {
            [styles.active]: tab === level,
          })}
          key={tab}
          onClick={() => onChange(tab)}
        >
          {`${t('friends.line')} ${tab + 1}`}
        </div>
      ))}
    </div>
  );
};

export default TabsReferrals;
