import { useCallback, useEffect, useRef } from 'react';
import { AdsGramErrorType } from 'shared/types/common';
import { ShowPromiseResult, AdController } from 'shared/types/adsgram';

export interface useAdsgramParams {
  blockId: string;
  onReward: (result: ShowPromiseResult) => void;
  onError?: (result: { type: AdsGramErrorType }) => void;
}

export const useAdsgram = ({
  blockId,
  onReward,
  onError = () => {},
}: useAdsgramParams): (() => Promise<void>) => {
  const AdControllerRef = useRef<AdController | undefined>(undefined);

  useEffect(() => {
    if (blockId) {
      const adController = window.Adsgram?.init({
        blockId,
        debug: false,
        debugBannerType: 'FullscreenMedia',
      });

      adController?.addEventListener('onBannerNotFound', () => {
        onError({ type: AdsGramErrorType.NO_ADS });
        console.log('onBannerNotFound no ads error');
      });
      adController?.addEventListener('onNonStopShow', () => {
        onError({ type: AdsGramErrorType.NO_ADS });
        console.log('onNonStopShow no ads error');
      });

      AdControllerRef.current = adController;
    } else {
      alert('No ads blockId');
      throw new Error('No ads blockId');
    }
  }, [blockId]);

  return useCallback(async () => {
    if (AdControllerRef.current) {
      AdControllerRef.current
        .show()
        .then((result) => {
          // user watch ad till the end
          onReward(result);
        })
        .catch((result: ShowPromiseResult) => {
          if (!result.error) {
            return onError({ type: AdsGramErrorType.UNKNOWN });
          }
        });
    } else {
      onError({ type: AdsGramErrorType.NO_SCRIPT });
    }
  }, [onError, onReward]);
};
