import { useLayoutEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { COMMON_PRELOAD_ASSETS, ROUTES } from 'shared/constants';

const APP_BG_ROUTES = [
  ROUTES.BAKERY,
  ROUTES.FRIENDS,
  ROUTES.MAIN,
  ROUTES.PROGRESS,
  ROUTES.MARKET,
  ROUTES.ONBOARDING,
  ROUTES.SETTINGS,
  ROUTES.CHOOSE_FINGER,
  ROUTES.CHOOSE_LANGUAGE,
  ROUTES.FAQ,
];

const SESSION_SCREEN_BG_ROUTES = [ROUTES.SESSION, ROUTES.CONGRATULATIONS, ROUTES.LIMIT_REACHED];

export const useAppBackground = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.body.style.backgroundPosition = 'center center';

    if (SESSION_SCREEN_BG_ROUTES.includes(location.pathname)) {
      document.body.style.backgroundImage = `url(${COMMON_PRELOAD_ASSETS.seesionBgImage})`;
      document.body.style.backgroundPosition = 'center center';
      return;
    }

    if (APP_BG_ROUTES.includes(location.pathname)) {
      document.body.style.backgroundImage = `url(${COMMON_PRELOAD_ASSETS.appBgImage})`;
      document.body.style.backgroundPosition = 'top center';
      return;
    }

    document.body.style.backgroundImage = `url(${COMMON_PRELOAD_ASSETS.defaultBgImage})`;
  }, [location.pathname]);
};
