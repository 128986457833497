import styles from './style.module.scss';
import { memo, useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { UITypography } from 'shared/ui';
import { DonutEntity } from 'shared/types';
import { getRandomBoolean } from 'shared/utils';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { StarsDonut } from 'components/lottie';
import { audioManager } from 'shared/service/audio';

export type DonutProps = DonutEntity & {
  onClick: (donut: DonutEntity) => { isCorrect: boolean | null; value: number };
  onAnimationComplete: (id: string) => void;
};

export const Donut = memo(
  // ({ size, type, onClick, id, positionX, value, fallDuration }: DonutProps) => {
  ({ size, type, onClick, id, positionX, value, fallDuration, startDelay }: DonutProps) => {
    // console.log(`Rendering donut: ${id}`);

    const [award, setAward] = useState<null | number>(null);
    const [isClicked, setIsClicked] = useState(false);

    const refs = {
      donut: useRef<HTMLDivElement>(null),
      image: useRef<HTMLDivElement>(null),
      award: useRef<HTMLDivElement>(null),
      container: useRef<HTMLDivElement>(null),
    };

    const [_, notificationOccurred] = useHapticFeedback();

    const onDonutClick = useCallback(() => {
      if (isClicked) return;
      setIsClicked(true);
      // console.log(`!!!! CLICK: ${type}, size=${size}, points=${value}`);

      const { isCorrect, value: _value } = onClick({
        id,
        size,
        positionX,
        type,
        value,
        fallDuration,
        // startDelay,
      });

      if (isCorrect) {
        // audioManager.play('coin'); // TODO: ...

        // notificationOccurred('error');
        for (let i = 0; i < 5; i++) {
          setTimeout(() => {
            notificationOccurred('error');
          }, i * 15);
        }

        setAward(_value);
        if (refs.image.current) {
          refs.image.current.style.opacity = '0';
        }
        return;
      }

      notificationOccurred('success');
      const leftDirection = getRandomBoolean();

      if (refs.image.current && refs.container.current) {
        refs.image.current.classList.add(leftDirection ? styles.rotateLeft : styles.rotateRight);

        refs.container.current.classList.add(
          leftDirection ? styles.jumpedLeft : styles.jumpedRight,
        );
      }
    }, [
      isClicked,
      onClick,
      id,
      size,
      positionX,
      type,
      value,
      fallDuration,
      notificationOccurred,
      startDelay,
    ]);

    const style = useMemo(
      () => ({
        animationDuration: `${fallDuration}s`,
        animationDelay: `${startDelay}s`,
        willChange: 'transform',
        ['--pos-x' as string]: `${positionX}px`,
        // ['--start-delay' as string]: `${startDelay}s`,
      }),
      [fallDuration, positionX], // , startDelay
    );

    return (
      <div
        id={id}
        className={styles['donut-container']}
        ref={refs.donut}
        style={style}
        onTouchStart={onDonutClick}
        onMouseDown={onDonutClick}
      >
        <div className={styles.donutOuterContainer} ref={refs.container}>
          <div id="donut-image" className={styles['donut-inner-container']} ref={refs.image}>
            <img src={`/donuts/${type}-donut-${size}.png`} alt={''} className={styles.donut} />

            <div className={styles.donutCenter} style={{ width: size, height: size }}></div>
            <div className={styles.stars}>
              <StarsDonut />
            </div>
          </div>

          {award && award > 0 && (
            <UITypography
              variant="Award"
              className={`${styles.award} `}
              id="award-element"
              ref={refs.award}
            >
              {`+${award}`}
            </UITypography>
          )}
        </div>
      </div>
    );
  },
  (prev, next) => {
    // console.log(`Comparing donuts: ${prev.id} === ${next.id}`);
    return prev.id === next.id;
  },
);
