import flyImage from 'assets/images/fly.png';

export const COMMON_PRELOAD_ASSETS = {
  // sessionBgImage: '/session-bg.png',
  // defaultBgImage: '/default-bg.png',
  // bakeryBgImage: '/bakery-bg.webp',
  // mainScreenBgImage: '/main-screen-bg.png',
  // bobeHero: '/bobe-hero.webp',
  // bobeHero2: '/bobe-hero_market.webp',
  // userAvatar: '/bobe-avatar-placeholder.webp'

  // screens_background - фон на всех окнах, кроме игровой сессии +
  // session_background - фон игровой сессии +
  // users_avatar - аватарки юзеров +
  // loading - экран загрузки и регистрации +
  // bakery - страница Пекарня, обмен токенов +
  // shop - страница маркета (там нужно будет текст о том, что раздел находится в разработке перенести вверх, чтобы не перекрывал картинки) +
  // donut_home - пончик на главной странице
  // bee_new - новые пчелки в игровой сессии (подсветку изменить с желтого цвета на фиолетовый или голубой)

  // new year
  // sessionBgImage: '/new-year/session_background.webp',
  // defaultBgImage: '/new-year/session_background.webp',
  defaultBgImage: '/default-bg.png',
  seesionBgImage: '/new-year/session_background.webp',
  appBgImage: '/new-year/screens_background.webp',

  bobeHero: '/new-year/loading.webp',
  bobeHero2: '/new-year/shop_bobe.webp',
  userAvatar: '/new-year/users_avatar.webp',
} as const;

export const PRELOAD_GAME_ASSETS = {
  flyImage,
} as const;
