import { AxiosResponse } from 'axios';
import { getTasks, getAchievements } from 'shared/api/queries/progress';
import { useProgressStore } from 'shared/store';
import { TaskEntity, AchievementEntity } from 'shared/types';
import { handleSettledPromise, replaceUrl } from 'shared/utils';

export const useProgress = () => {
  const { setDynamicAchievements, setStaticAchievements, setTasks } = useProgressStore(
    (state) => state,
  );

  const fetchTasksAndAchievements = async () => {
    try {
      const [tasksRes, staticAchievementsRes, dynamicAchievementsRes] = await Promise.allSettled([
        getTasks(),
        getAchievements('static'),
        getAchievements('dynamic'),
      ]);

      handleSettledPromise<AxiosResponse<TaskEntity[]>>({
        promise: tasksRes,
        callback: (result) => {
          setTasks(result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })));
        },
      });
      handleSettledPromise<AxiosResponse<AchievementEntity[]>>({
        promise: staticAchievementsRes,
        callback: (result) => {
          setStaticAchievements(
            result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })),
          );
        },
      });
      handleSettledPromise<AxiosResponse<AchievementEntity[]>>({
        promise: dynamicAchievementsRes,
        callback: (result) => {
          setDynamicAchievements(
            result.data.map((item) => ({ ...item, icon: replaceUrl(item.icon) })),
          );
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    fetchTasksAndAchievements,
  };
};
