import Lottie from 'lottie-react';
import animationData from 'assets/lottie/dead-fly-new-year.json';
// import animationData from 'assets/lottie/dead-fly.json';

export const DeadFly = () => {
  return (
    <Lottie
      style={{ pointerEvents: 'none' }}
      height={200}
      width={200}
      animationData={animationData}
      loop
      autoplay
    />
  );
};
